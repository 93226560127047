$white: #fff;
$bg-gray-input: #FAFAFA;
$gray-100: #f8f9ff;
$gray-200: #f4f5fd;
$gray-300: #eeeff8;
$gray-400: #e6e7f1;
$gray-500: #dfe0ea;
$gray-600: #d1d2db;
$gray-700: #a6a6b9;
$gray-800: #7a7b97;
$gray-900: #3b3e66;
$black: #070919;
$red: #f83245;
$green: #1bc;
$cyan: #11c5db;

$first: #01a3a4;
$primary: #01a3a4;
$warning: #f4772e;
$secondary: $bg-gray-input;
$success: $green;
$info: $cyan;
$danger: $red;
$light: $gray-200;
$dark: $gray-800;

$dropdown-border-radius: 0.29rem;
$dropdown-border-width: 1px;
$box-shadow-sm: 0 0.313rem 0.8rem rgba($gray-800, .5), 0 0.126rem 0.225rem rgba($gray-800, .3);
$dropdown-link-hover-bg: $gray-200;
$dropdown-link-hover-color: $black;

$zindex-modal-backdrop: 1340;
$modal-backdrop-opacity: .8;
$modal-backdrop-bg: $black;


$datepicker__background-color: theme-color("primary");
$datepicker__border-color: rgba($black, .15);
$datepicker__highlighted-color: $primary;
$datepicker__muted-color: $gray-600;
$datepicker__selected-color: theme-color("primary");
$datepicker__text-color: $gray-700;
$datepicker__header-color: $gray-500;
$datepicker__navigation-disabled-color: $datepicker__muted-color;

$datepicker__border-radius: $dropdown-border-radius;
$datepicker__day-margin: 0.166rem;
$datepicker__font-size: 0.75rem;
$datepicker__item-size: 2rem;
$datepicker__margin: 0.5rem;
$datepicker__navigation-size: 0.45rem;
$datepicker__triangle-size: 6px;