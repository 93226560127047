@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Readex+Pro:wght@300;400;500;700&display=swap');
$fontReadex: 'Readex Pro', sans-serif;

.feedback {
    font-family: $fontReadex;

    &-group {
        margin-bottom: calc(6rem - 2.25rem);

        &-title {
            font-size: 1.625rem !important;
        }
        
        &-btn__submit, &-btn__view-dashboard {
            padding: 0.75rem 1rem;
            outline: none;
            background-color: #01A4A4;
            font-weight: 500;
            border: none;

            &:not([disabled]):hover,
            &:not([disabled]):active,
            &:not([disabled]):focus {
                background-color: #009492;
                box-shadow: none;
            }
        }

        &-btn__cancel {
            padding: 0.75rem 1rem;
            outline: none;
            background-color: #fff;
            color: #211B21;
            border: 1px solid #211B21;
            font-weight: 500;

            &:not([disabled]):hover,
            &:not([disabled]):active,
            &:not([disabled]):focus {
                background-color: #fff;
                color: #211B21;
                box-shadow: none;
            }
        }

    }

    &-note {
        font-size: 0.75rem;
        margin-top: 1.25rem;
        margin-bottom: 0;
        font-weight: 360;
        color: #000;
    }

    &-dialog {
        width: 14.375rem;
        height: 11.25rem;
        padding: 1.25rem 1.25rem 0 1.25rem;
        background: #fff;
        display: flex;
        box-shadow: 0px 4px 94px rgba(0, 0, 0, 0.11);
        position: fixed;
        right: 1.25rem;
        top: 40%;
        border-radius: 8px;
        z-index: 999;

        p {
            margin-bottom: 0;
            font-size: 0.75rem;
            font-family: $fontReadex;
        }

        button {
            height: fit-content;
            margin-left: 0.5rem;
            color: #211B21;
            background: #fff;
            border: none;

            &:not([disabled]):hover,
            &:not([disabled]):active,
            &:not([disabled]):focus {
                background-color: #fff !important;
                color: #211B21 !important;
                box-shadow: none !important;
            }
        }
    }

    .font-weight-600 {
        font-weight: 600;
    }

    .mb-36 {
        margin-bottom: 2.25rem;
    }
}

.feedback-contact {
    p {
        font-family: $fontReadex;
        font-size: 1.375rem;
        font-weight: 500;
        color: #211B21;

        span {
            color: #035BDE;
        }
    }
}

.inset-0 {
    inset: 0;
}

.z-99 {
    z-index: 99;
}

@media (max-width: 768px) {
    .feedback {
        &-group {
            margin-bottom: 1.5rem;

            &-title {
                font-size: 1.25rem !important;
            }
        }
    }
    .feedback-contact {
        p {
            font-size: 1.25rem;
        }
    }
}

@media (max-width: 575.98px) {
    .feedback-contact {
        p {
            font-size: 0.8rem;
        }
    }
}