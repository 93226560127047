/*--------Color Mapping----------------------------
    #EBF3FF	blue-100
    #BFE8FF	blue-100
    #BFE8FE	blue-100
    #D0E4FF	blue-100
    #52C0E5	blue-300
    #021BFF	blue-800
    #035ADE	blue-800
    #4F52C5	blue-800
    #3C44B1	blue-800
    #E0FDEC	green-100
    #DFF9EC	green-100
    #D5F6F3	green-100
    #CDFFE2	green-100
    #D9F1F1	green-100
    #E0FFFF	green-100
    #4ED485	green-300
    #01A3A4	green-400
    #01A4A4	green-400
    #F5F5F5	neutral-100
    #FAFAFA	neutral-100
    #F4F4F4	neutral-100
    #F2FAFD	neutral-100
    #F9F9F9	neutral-100
    #E9ECF1	neutral-200
    #F2F2F2	neutral-200
    #F5F4F2	neutral-200
    #F1F4F8	neutral-200
    #EFEFFF	neutral-200
    #F1F1F1	neutral-200
    #E9E9E9	neutral-200
    #E5E5E5	neutral-300
    #D3D4DC	neutral-300
    #EDEBE8	neutral-300
    #DDDAD6	neutral-300
    #D6DBE4	neutral-300
    #D2D2D2	neutral-400
    #D1D2DB	neutral-400
    #ADB2BA	neutral-400
    #C2C4C6	neutral-400
    #ADB2B9	neutral-400
    #D3D3D3	neutral-400
    #D5D5D5	neutral-400
    #C4C4C4	neutral-400
    #A2A2A2	neutral-500
    #979797	neutral-500
    #969798	neutral-500
    #897F86	neutral-500
    #959BA3	neutral-500
    #98A0AB	neutral-500
    #828B93	neutral-600
    #797672	neutral-600
    #8F96A0	neutral-600
    #79808D	neutral-600
    #6E5E5E	neutral-700
    #6B6A66	neutral-700
    #FFDD88	orange-100
    #FFDFC8	orange-100
    #FFD993	orange-100
    #FFE173	orange-100
    #FFC86E	orange-200
    #F5D06B	orange-200
    #FFBA49	orange-300
    #FFB949	orange-300
    #FFF2CE	orange-50
    #FFFBE6	orange-50
    #FFFBF2	orange-50
    #FFF1CD	orange-50
    #FFF0D4	orange-50
    #FFF6CA	orange-50
    #FF9E0C	orange-500
    #FF8F00	orange-600
    #818BA4	purplish-500
    #7A7B97	purplish-500
    #AF5E6D	purplish-500
    #6084B6	purplish-500
    #4E5A6B	purplish-600
    #364256	purplish-600
    #415066	purplish-600
    #1E2C42	purplish-800
    #1F2C41	purplish-800
    #3B3E66	purplish-800
    #371C36	purplish-800
    #343434	purplish-800
    #3A2935	purplish-800
    #2B3444	purplish-800
    #1A4688	purplish-800
    #000000	purplish-900
    #070919	purplish-900
    #21191D	purplish-900
    #26131F	purplish-900
    #FFAE8B	red-100
    #FDC7C4	red-100
    #FF6285	red-300
    #FF73C7	red-300
    #FFF3E9	red-50
    #FFEFEF	red-50
    #FC6E47	red-500
    #F86158	red-600
    #F45255	red-600
    #F83245	red-600
    #FF3366	red-600
    #B11E1E	red-900
    #FFFFFF	neutral-50
------------------------------------*/

/*--------Old Color Variables----------------------------
    $Salmon:            #F86158;
    $Midnight:          #1F2C41;
    $Eggshell:          #FFF2CE;
    $Gold:              #FFBA49;
    $White:             #FFFFFF;
    $OxfordBlue:        #364256;
    $ShadowBlue:        #818BA4;
    $Solitude:          #E9ECF1;
    $Snow:              #F9F9F9;
    $Emerald:           #4ED485;
    $GoldenGlow:        #FFDD88;
    $PeachGlow:         #FFDFC8;
    $Black_30:          #21111A; // not used
    $RedInvited:        #FC6E47; // not used
    $BlueSent:          #6084B6; // not used
    $BlueCompleted:     #035ADE; // not used
    $GreenLight:        #4ED485; // not used
------------------------------------*/

$Radical:           #020101; // not found
$BackDim_70:        rgba(0, 0, 0, 0.7); // not found

$red-900: #B83519;
$red-800: #C73F25;
$red-700: #D4452C;
$red-600: #E64F31;
$red-500: #F35731;
$red-400: #F0644D;
$red-300: #E77D72;
$red-200: #F1A199;
$red-100: #FFD1D1;
$red-50: #FFECEE;
$orange-900: #E85300;
$orange-800: #F06E00;
$orange-700: #F67F00;
$orange-600: #FC8F00;
$orange-500: #FF9B00;
$orange-400: #FFAC1D;
$orange-300: #FFB940;
$orange-200: #FFCD71;
$orange-100: #FFE4B1;
$orange-50: #F7F3EB;
$green-900: #004B45;
$green-800: #006763;
$green-700: #007773;
$green-600: #008784;
$green-500: #009492;
$green-400: #01A4A4;
$green-300: #42B4B5;
$green-200: #7BCACB;
$green-100: #BDDEDD;
$green-50: #CEDEDB;
$purplish-900: #21111A;
$purplish-800: #331A28;
$purplish-700: #422134;
$purplish-600: #513144;
$purplish-500: #5C2E49;
$purplish-400: #914270;
$purplish-300: #B4759A;
$purplish-200: #C489AB;
$purplish-100: #DDBCCF;
$purplish-50: #F0E1EA;
$neutral-900: #1F1D1A;
$neutral-800: #403E3A;
$neutral-700: #5F5C58;
$neutral-600: #73706C;
$neutral-500: #9C9994;
$neutral-400: #BBB8B4;
$neutral-300: #DDDAD6;
$neutral-200: #EDEBE8;
$neutral-100: #F5F4F2;
$neutral-50: #FFFFFF;
$blue-900: #1838BF;
$blue-800: #035BDE;
$blue-700: #006DF1;
$blue-600: #0080FF;
$blue-500: #008FFF;
$blue-400: #04A0FF;
$blue-300: #4EB1FF;
$blue-200: #87C7FF;
$blue-100: #B8DCFF;
$blue-50: #E1F1FF;
$magenta-900: #82244D;
$magenta-800: #A72B56;
$magenta-700: #BC2F5A;
$magenta-600: #D2345F;
$magenta-500: #E33862;
$magenta-400: #E74E78;
$magenta-300: #EC6A90;
$magenta-200: #F293AF;
$magenta-100: #F7BDCF;
$magenta-50: #FBE5EB;

$FontNunito:    "Nunito";
$FontReadexPro: "Readex Pro";

:export {
    radical: $Radical;
    backDim_70: $BackDim_70;
    red_400: $red-400;
    red-900: $red-900;
    red-800: $red-800;
    red-700: $red-700;
    red-600: $red-600;
    red-500: $red-500;
    red-400: $red-400;
    red-300: $red-300;
    red-200: $red-200;
    red-100: $red-100;
    red-50: $red-50;
    orange-900: $orange-900;
    orange-800: $orange-800;
    orange-700: $orange-700;
    orange-600: $orange-600;
    orange-500: $orange-500;
    orange-400: $orange-400;
    orange-300: $orange-300;
    orange-200: $orange-200;
    orange-100: $orange-100;
    orange-50: $orange-50;
    green-900: $green-900;
    green-800: $green-800;
    green-700: $green-700;
    green-600: $green-600;
    green-500: $green-500;
    green-400: $green-400;
    green-300: $green-300;
    green-200: $green-200;
    green-100: $green-100;
    green-50: $green-50;
    purplish-900: $purplish-900;
    purplish-800: $purplish-800;
    purplish-700: $purplish-700;
    purplish-600: $purplish-600;
    purplish-500: $purplish-500;
    purplish-400: $purplish-400;
    purplish-300: $purplish-300;
    purplish-200: $purplish-200;
    purplish-100: $purplish-100;
    purplish-50: $purplish-50;
    neutral-900: $neutral-900;
    neutral-800: $neutral-800;
    neutral-700: $neutral-700;
    neutral-600: $neutral-600;
    neutral-500: $neutral-500;
    neutral-400: $neutral-400;
    neutral-300: $neutral-300;
    neutral-200: $neutral-200;
    neutral-100: $neutral-100;
    neutral-50: $neutral-50;
    blue-900: $blue-900;
    blue-800: $blue-800;
    blue-700: $blue-700;
    blue-600: $blue-600;
    blue-500: $blue-500;
    blue-400: $blue-400;
    blue-300: $blue-300;
    blue-200: $blue-200;
    blue-100: $blue-100;
    blue-50: $blue-50;
    magenta-900: $magenta-900;
    magenta-800: $magenta-800;
    magenta-700: $magenta-700;
    magenta-600: $magenta-600;
    magenta-500: $magenta-500;
    magenta-400: $magenta-400;
    magenta-300: $magenta-300;
    magenta-200: $magenta-200;
    magenta-100: $magenta-100;
    magenta-50: $magenta-50;
}